import {
  useFieldArray,
  type UseFieldArrayReturn,
  useForm,
} from 'react-hook-form';
import useSWR from 'swr';

import { type DtoBrand } from '@lp-lib/api-service-client/public';
import { type Block, BlockType } from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { NotificationType, type Tag } from '../../types';
import { type Game } from '../../types/game';
import { err2s, uuidv4 } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { BlockIcon } from '../icons/Block';
import { Loading } from '../Loading';
import { useNotificationDataSource } from '../Notification/Context';

type SelectableBlock = Block & { selected?: boolean };

type FormData = {
  name: string;
  tags: Tag[];
  blocks: SelectableBlock[];
};

function skipBlock(block: Block) {
  return (
    block.type === BlockType.TITLE_V2 ||
    block.type === BlockType.SPOTLIGHT ||
    block.type === BlockType.SPOTLIGHT_V2 ||
    block.type === BlockType.SCOREBOARD ||
    block.type === BlockType.RANDOMIZER ||
    block.type === BlockType.INSTRUCTION
  );
}

function BlockEntry(props: {
  block: SelectableBlock;
  index: number;
  onToggle: () => void;
}) {
  const { block, index } = props;
  const summary = BlockKnifeUtils.Summary(block);
  const disabled = skipBlock(block) || !!block.brandId;
  return (
    <div
      className={`flex items-center gap-2 ${
        disabled ? 'text-secondary' : 'text-white'
      }`}
    >
      <input
        type='checkbox'
        className='checkbox-dark'
        checked={!!block.selected}
        disabled={disabled}
        onChange={props.onToggle}
      />
      <div className='text-xs'>{index + 1}</div>
      <div className='w-24 h-13.5 relative flex-shrink-0'>
        <img
          src={
            MediaUtils.PickMediaUrl(summary.coverMedia, {
              priority: [MediaFormatVersion.SM],
              videoThumbnail: 'first',
            }) || placeholder
          }
          alt='cover'
          className='object-cover w-full h-full rounded border border-secondary'
        />
        <BlockIcon
          blockType={block.type}
          className='absolute right-1.5 top-1.5 w-2.5 h-2.5'
        />
      </div>
      <div className='flex items-center'>
        <p className='text-sms line-clamp-3'>{summary.title}</p>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
type BlockFieldArray = UseFieldArrayReturn<FormData, 'blocks', 'key'>;

function BlockList(props: { blockFieldArray: BlockFieldArray }) {
  const { blockFieldArray } = props;
  const blocks = blockFieldArray.fields;
  if (blocks.length === 0) {
    return (
      <div className='text-icon-gray w-full h-15 flex items-center justify-center'>
        Choose blocks from the right panel
      </div>
    );
  }
  return (
    <div
      className={`w-full h-full flex flex-col items-center flex-shrink-0 gap-4 max-h-120 overflow-auto scrollbar`}
    >
      {blocks.length > 0 && (
        <div className='flex flex-col w-full gap-2'>
          {blocks.map((b, i) => (
            <BlockEntry
              key={b.id}
              block={b}
              index={i}
              onToggle={() => {
                blockFieldArray.update(i, { ...b, selected: !b.selected });
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function GameToBrandForm(props: {
  game: Game;
  blocks: Block[];
  onCancel: () => void;
  onConfirm: (brand: DtoBrand) => void;
}) {
  const { game, blocks } = props;
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      name: game.name,
      tags: game.tags ?? [],
      blocks: blocks.map((b) => ({
        ...b,
        selected: skipBlock(b) || !!b.brandId ? false : true,
      })),
    },
  });
  const blockFieldArray = useFieldArray<FormData, 'blocks', 'key'>({
    control: control,
    name: 'blocks',
    keyName: 'key',
  });
  const selectedBlocks = blockFieldArray.fields.filter((b) => !!b.selected);

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useLiveAsyncCall(async (data: FormData) => {
    if (selectedBlocks.length === 0) return;
    const resp = await apiService.brand.create({
      name: data.name,
      showcaseText: '',
      blockLengthSec: 0,
      predefinedBlockData: [],
      tags: data.tags.map((tag) => tag.name),
      blockIds: selectedBlocks.map((block) => block.id),
    });
    return resp.data.brand;
  });

  const onSubmit = handleSubmit(async (data: FormData) => {
    resetSubmit();
    const brand = await submit(data);
    if (!brand) return;
    props.onConfirm(brand);
  });

  return (
    <form className='w-full flex flex-col' onSubmit={onSubmit}>
      <BlockList blockFieldArray={blockFieldArray} />
      <div className='w-full flex items-center justify-center text-xs my-1 gap-3'>
        <span>Total: {blockFieldArray.fields.length}</span>
        <span>|</span>
        <span className='font-bold'>Selected: {selectedBlocks.length}</span>
        <span>|</span>
        <span>
          Invalid Type:{' '}
          {blockFieldArray.fields.filter((b) => skipBlock(b)).length}
        </span>
        <span>|</span>
        <span>
          Belongs to another Brand:{' '}
          {blockFieldArray.fields.filter((b) => !!b.brandId).length}
        </span>
      </div>
      <div className='flex items-center justify-center gap-2'>
        <button
          type='button'
          className='btn-secondary w-40 h-10'
          onClick={props.onCancel}
        >
          Cancel
        </button>
        <button
          type='submit'
          className='btn-primary w-40 h-10 flex items-center justify-center gap-1'
          disabled={selectedBlocks.length === 0 || submitState.isRunning}
        >
          {submitState.isRunning ? <Loading text='' /> : null}
          Convert
        </button>
      </div>
      {submitError && (
        <div className='text-2xs text-red-002'>{err2s(submitError)}</div>
      )}
    </form>
  );
}

function BrandImportModal(props: {
  game: Game;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { game } = props;
  const {
    data: blocks,
    isLoading,
    mutate,
  } = useSWR(`/games/${game.id}/blocks`, async () => {
    return (await apiService.block.getBlocksByGameId(game.id)).data.blocks;
  });
  const notificationDataSource = useNotificationDataSource();

  return (
    <ModalWrapper containerClassName='w-160 min-h-[160]' borderStyle='white'>
      <div className='w-full h-full flex flex-col items-center justify-center gap-4 px-2 py-4'>
        <div className='text-2xl font-bold'>Convert Minigame to Brand</div>
        {isLoading ? (
          <Loading />
        ) : (
          <GameToBrandForm
            game={game}
            blocks={blocks ?? []}
            onCancel={props.onCancel}
            onConfirm={(brand: DtoBrand) => {
              mutate();
              notificationDataSource.send({
                id: uuidv4(),
                toUserClientId: '',
                type: NotificationType.BrandAction,
                createdAt: Date.now(),
                metadata: { brand },
              });
              props.onConfirm();
            }}
          />
        )}
      </div>
    </ModalWrapper>
  );
}

export function useTriggerBrandImportModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback((game: Game) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <BrandImportModal
          game={game}
          onCancel={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
        />
      ),
    });
  });
}
